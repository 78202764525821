<template>
  <div class="page_bg newpage" style="margin:0;">
    <div class="logo_img flex-r-s-s">
      <van-image v-if="isLogin" width="4.27rem" height="1.97rem"
                 src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/login/login_logo2.png"
      />
      <van-image v-else width="4.27rem" height="1.97rem"
                 src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/login/login_logo3.png"
      />
    </div>
    <div class="login_form flex-c-s-c">
      <van-form @submit="onSubmit">
        <template v-if="!isLogin">
          <div class="flex-r-s-c">
            <van-image width="0.43rem" height="0.53rem" src="https://oss.xiaoyi120.com/shop2.0/login/login_icon3.png" />
            <div class="login_form_msg">所在地区</div>
          </div>
          <van-field readonly clickable :value="loginForm.districtName" placeholder="请选择您的地址" right-icon="arrow"
                     @click="showPicker = true"
          />
        </template>
        <template v-if="!isLogin">
          <div style="margin-top:0.6rem" class="flex-r-s-c">
            <van-image width="0.43rem" height="0.53rem" src="https://oss.xiaoyi120.com/shop2.0/login/login_icon4.png" />
            <div class="login_form_msg">机构名称</div>
          </div>

          <van-field v-model="loginForm.companyName" type="text" name="companyName" placeholder="请输入您的机构名称"
                     :rules="[{ required: true, message: '' }]"
          />
        </template>

        <div :style="{ marginTop: isLogin? '0rem' :'0.6rem' }" class="flex-r-s-c">
          <van-image width="0.43rem" height="0.53rem" src="https://oss.xiaoyi120.com/shop2.0/login/login_icon1.png" />
          <div class="login_form_msg">手机号</div>
        </div>
        <van-field v-model="loginForm.mobile" type="tel" name="mobile" placeholder="请输入手机号" maxlength="11"
                   :rules="[{ required: true, message: '' }]"
        />
        <div style="margin-top:0.6rem" class="flex-r-s-c">
          <van-image width="0.43rem" height="0.53rem" src="https://oss.xiaoyi120.com/shop2.0/login/login_icon2.png" />
          <div class="login_form_msg">验证码</div>
        </div>
        <van-field v-model="loginForm.checkCode" type="digit" class="van-field" name="checkCode" placeholder="请输入验证码"
                   maxlength="6" :rules="[{ required: true, message: '' }]"
        >
          <template #extra>
            <van-count-down v-if="isSend" :time="time" format="ss 秒重新发送" @finish="finish" />
            <div v-else class="send_message" @click="sendMessage">获取验证码</div>
          </template>
        </van-field>
        <div class="agree_form">
          <van-checkbox v-model="checked" checked-color="#0767ab" icon-size="0.4rem">
            点击登录/注册即视为您已同意<span class="f-blue" @click="toAgreement">《用户注册协议》</span>
          </van-checkbox>
        </div>
        <div v-if="isLogin" class="login_sub" :style="{ marginTop: isLogin? '0.97rem' :'0.57rem' }">
          <van-button round block :loading="loading" type="info" loading-text="登录中..." native-type="submit"
                      color="#0767ab" style="font-size: 0.43rem;"
          >立即登录
          </van-button>
        </div>
        <div v-else class="login_sub">
          <van-button round block :loading="loading" type="info" loading-text="注册中..." native-type="submit"
                      color="#0767ab" style="font-size: 0.43rem;"
          >立即注册
          </van-button>
        </div>
      </van-form>
    </div>
    <div class="login_register flex-r-s-c" @click="toRegister">
      {{ isLogin ? '没有账号？立即注册':'已有账号？立即登录' }}
      <van-image width="0.53rem" height="0.53rem"
                 src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/login/login_arrow_right.png"
      />
    </div>
    <!-- <div class="login_register_agree">
      <van-checkbox v-model="checked" checked-color="#0767ab" icon-size="0.4rem">
        点击登录/注册即视为您已同意<span class="f-blue" @click="toAgreement">《用户注册协议》</span>
      </van-checkbox>
    </div> -->
    <!-- <div class="bottom_xieyi flex-r-c-c">
      点击登录即视为您已同意<span class="f-blue" @click="toAgreement">《用户注册协议》</span>
      和<span class="f-blue" @click="toPrivacy">《隐私政策》</span>
    </div> -->
    <!-- 省市区部分 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-cascader v-model="cascadervalue" title="请选择所在地区" :options="columns" :field-names="fieldNames"
                    @close="closePick" @finish="finishPick"
      />
    </van-popup>
  </div>
</template>

<script>
import './index.scss'
// 接口
import my from '../../assets/js/lbc'
import { districts } from '@/api/public'
import { sms_send, login_mobile, mobile_register3 } from '@/api/user'
import { GetOpenID } from '@/api/public'
export default {
  data() {
    return {
      isSend: false,
      loading: false,
      time: 60 * 1000,
      showPicker: false,
      cascadervalue: '',
      columns: [],
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children'
      },
      loginForm: {
        mobile: '', checkCode: '', openId: '', platformType: 'wxmp' // 微信(H5):wxmp 微信:wx
      },

      isLogin: true,

      checked: false
    }
  },
  created() {
    console.log('openId 1', localStorage.getItem('openId'), typeof localStorage.getItem('openId'))
    console.log('openId 2', this.$store.getters.openid, typeof this.$store.getters.openid)
    if (localStorage.getItem('openId') || this.$store.getters.openid) {
      this.loginForm.openId = localStorage.getItem('openId') || this.$store.getters.openid
      console.log('openId 3 ', this.loginForm.openId)
    } else {
      this.getOpenid()
      console.log('openId 4 应该执行重新获取openid')
    }
    this.loadDistrict()
  },
  methods: {
    // 获取openid
    getOpenid() {
      // 微信公众号appid-开发-基本配置中获取
      const appId = my.appId; const toPath = my.host + '/#' + this.$route.path
      // 核心步骤，获取code
      const hrefurl = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
        appId + '&redirect_uri=' + encodeURIComponent(toPath) + '&response_type=code&scope=snsapi_base&state=#wechat_redirect'
      // 从地址栏获取code
      const code = my.getQueryString('code')
      if (code) {
        GetOpenID(code).then(res => {
          if (res.code === 20005) {
            this.$toast.clear(true); sessionStorage.setItem('jump', true); window.location.replace(hrefurl)
          } else {
            localStorage.setItem('openId', res.data)
            this.loginForm.openId = res.data
          }
        }).catch(error => {
          if (error === 20005) {
            // this.$toast.clearAll(true)
          } else { this.$toast('请求失败！') }
        })
      } else {
        window.location.replace(hrefurl)
      }
    },
    // 点击登录
    onSubmit(values) {
      if (this.checked) {
        if (this.isLogin) {
          this.loading = true
          login_mobile(this.loginForm).then(res => {
            sessionStorage.setItem('tabberJump', true)
            res.data.openid = this.openid
            res.data.ref = this.url
            this.$utils.storeUserInfo(this.$store, res.data)
            this.loading = false
            this.$utils.localLogin(this, res.data, 'login_pet')
            this.$nextTick(() => { sessionStorage.setItem('liveFlag', 1); sessionStorage.setItem('liveFlag_cart', 1) })
          }).catch(err => {
            console.log(err)
            this.loading = false
          })
        } else {
          this.loginForm.categoryId = 1
          this.loading = true
          mobile_register3(this.loginForm).then(res => {
            this.$toast({ message: '注册成功！', duration: 3 * 1000 })
            res.data.openid = this.openid
            res.data.ref = this.url
            this.$utils.storeUserInfo(this.$store, res.data)
            this.loading = false
            this.$utils.localLogin(this, res.data, 'login_pet')
            this.$nextTick(() => { sessionStorage.setItem('liveFlag', 1); sessionStorage.setItem('liveFlag_cart', 1) })
          }).catch(err => {
            console.log(err)
            this.loading = false
          })
        }
      } else {
        this.$toast({
          message: '请勾选同意后进行操作'
        })
      }
    },
    // 发送短信
    sendMessage() {
      if (this.loginForm.mobile.length === 11) {
        const parms = { mobile: this.loginForm.mobile, type: 'login' }
        sms_send(parms).then(res => {
          this.$toast({ message: '已发送！', duration: 3 * 1000 })
          this.isSend = true
        })
      } else {
        this.$toast({ message: '请输入手机号', position: 'top', duration: 3 * 1000 })
      }
    },
    // 倒计时结束触发
    finish() { this.isSend = false },
    // 去注册
    toRegister() {
      this.$router.push('register')
      // this.cascadervalue = ''
      // this.loginForm = {
      //   provinceId: '', cityId: '', areaId: '', districtName: '', companyName: '',
      //   mobile: '', checkCode: '', openId: '', platformType: 'wxmp'
      // }
      // this.isSend = false
      // this.isLogin = !this.isLogin
    },
    // 省市区
    // 加载省市区
    loadDistrict() { districts().then(res => { this.columns = res.data }) },
    // 去注册协议
    toAgreement() { this.$router.push('agreement') },
    // 去隐私政策
    toPrivacy() { this.$router.push('privacy') },

    // 选择结束
    finishPick(value) {
      console.log(value)
      this.showPicker = false
      this.loginForm.districtName = value.selectedOptions[0].label + '-' + value.selectedOptions[1].label + '-' + value.selectedOptions[2].label
      this.loginForm.provinceId = value.selectedOptions[0].value
      this.loginForm.cityId = value.selectedOptions[1].value
      this.loginForm.areaId = value.selectedOptions[2].value
    },
    // 关闭选择
    closePick() {
      this.showPicker = false
    }

  }
}
</script>

<style>
html {
  background: #fff;
}
.newpage {
  background: url("https://oss.xiaoyi120.com/shop2.0/login/login_bg.png");
  background-size: 10rem 10.13rem;
  background-repeat: no-repeat;
}
</style>
